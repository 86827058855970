import React from "react"

import Layout from "../../components/layout"

const NotFoundPageEn = ({pageContext}) => (
  <Layout language={pageContext.language}>
    <div className={"page-content"}>
      <div className={"container"}>
        <div className="content-wrapper">
          <div className="content">
            <h1>ERROR 404. PAGE NOT FOUND</h1>
            <p>Requested page not found.</p>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default NotFoundPageEn
